import { httpsCallable } from 'firebase/functions'
import { db, functions, generateId } from 'controllers/db'
import _ from 'lodash'
import { DBT } from 'types/internal'
import {
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc
} from 'firebase/firestore'

export const getLogs = async (interactionId: string) => {
  try {
    console.log('fetchLogs start')
    const callChatgpt = httpsCallable(functions, 'getTwoFactorLogs')
    const res = await callChatgpt({
      interactionId
    })
    console.log('callable function res:', res)
    return res.data
  } catch (e) {
    console.warn('error', e)
    return { error: _.get(e, 'message') }
  }
}

export const dbSubscribeToConf = (
  callback: (conf: DBT.AIPromptConfT) => void
) => {
  try {
    const ref = doc(collection(db, 'settings'), 'vegasConf')
    const unsubscribe = onSnapshot(
      ref,
      sn => {
        const flexConf = sn.data() as DBT.AIPromptConfT | undefined
        if (flexConf) {
          callback(flexConf)
        }
      },
      err => {
        console.log(`dbSubscribeToConf error: ${err.message}`)
      }
    )
    return unsubscribe
  } catch (e) {
    console.log('dbSubscribeToConf error', e)
    return null
  }
}

export const dbSaveVegasConfTemplate = async (
  name: string,
  conf: DBT.AIPromptConfT
) => {
  const id = generateId()
  const t = {
    id,
    name,
    createdAt: _.now(),
    conf
  }
  const ref = doc(collection(db, 'settings/vegasConf/templates'), t.id)
  await setDoc(ref, t)
}

export const dbSubscribeToVegasTemplates = (
  callback: (templates: DBT.AIConfTemplateT[]) => void
) => {
  try {
    const ref = query(
      collection(db, 'settings/vegasConf/templates'),
      orderBy('createdAt', 'desc')
    )
    const unsubscribe = onSnapshot(
      ref,
      snapshot => {
        const templates: DBT.AIConfTemplateT[] = []
        snapshot.forEach(doc => {
          const template = doc.data() as DBT.AIConfTemplateT
          templates.push(template)
        })
        callback(templates)
      },
      err => {
        console.log(`dbSubscribeToTemplates error: ${err.message}`)
      }
    )
    return unsubscribe
  } catch (e) {
    console.log('dbSubscribeToTemplates error', e)
    return null
  }
}

export const dbUpdateConf = async (conf: Partial<DBT.AIPromptConfT>) => {
  const ref = doc(collection(db, 'settings'), 'vegasConf')
  await setDoc(ref, conf)
}

export const dbSubscribeToUser = (
  userId: string,
  callback: (user: object) => void
) => {
  try {
    const ref = doc(collection(db, 'vegasUsers'), userId)
    const unsubscribe = onSnapshot(
      ref,
      sn => {
        const user = sn.data() as object | undefined
        if (user) {
          callback(user)
        }
      },
      err => {
        console.log(`subscribeToUser error: ${err.message}`)
      }
    )
    return unsubscribe
  } catch (e) {
    console.log('subscribeToUser error', e)
    return null
  }
}

export const dbSaveVegasUser = (user: Record<string, any>) => {
  // console.log('dbSaveVegasUser', user)
  const userId = _.get(user, 'id')
  const ref = doc(collection(db, 'vegasUsers'), userId)
  setDoc(ref, user)
}

export const dbDeleteVegasUser = async (userId: string) => {
  try {
    console.log('dbDeleteVegasUser start')
    const f = httpsCallable(functions, 'deleteVegasUser')
    const res = await f({
      userId
    })
    console.log('callable function res:', res)
    return res.data
  } catch (e) {
    console.warn('error', e)
    return { error: _.get(e, 'message') }
  }
}

export const addVegasUserPhotos = async (userId: string, urls: string[]) => {
  try {
    console.log('addVegasUserPhotos start')
    const f = httpsCallable(functions, 'addVegasUserPhotos')
    const res = await f({
      userId,
      urls
    })
    console.log('callable function res:', res)
    return res.data
  } catch (e) {
    console.warn('error', e)
    return { error: _.get(e, 'message') }
  }
}

export const dbDeleteVegasUserPhoto = async (
  userId: string,
  photoId: string
) => {
  try {
    console.log('deleteVegasPhoto start')
    const f = httpsCallable(functions, 'deleteVegasUserPhoto')
    const res = await f({ userId, photoId })
    console.log('callable function res:', res)
    return res.data
  } catch (e) {
    console.warn('error', e)
    return { error: _.get(e, 'message') }
  }
}

export const dbCreateVegasUser = (user: Record<string, any>) => {
  console.log('dbCreateVegasUser', user)
  const ref = doc(collection(db, 'vegasUsers'), user.id)
  setDoc(ref, user)
}

export const dbSearchVegasUserByPhoto = async (photo: string) => {
  try {
    console.log('dbSearchVegasUserByPhoto start')
    const f = httpsCallable(functions, 'searchVegasUserByPhoto')
    const res = await f({ photo })
    console.log('callable function res:', res)
    return res.data
  } catch (e) {
    console.warn('error', e)
    return { error: _.get(e, 'message') }
  }
}

export const dbValidateEmail = async (email: string) => {
  try {
    console.log('validateEmail start')
    const f = httpsCallable(functions, 'validateEmail')
    const res = await f({ email })
    console.log('callable function res:', res)
    return res.data
  } catch (e) {
    console.warn('error', e)
    return { error: _.get(e, 'message') }
  }
}

export const dbSyncToProd = async (
  collectionId: string,
  documentId: string
) => {
  try {
    console.log('dbSyncToProd start')
    const f = httpsCallable(functions, 'syncToProd')
    const res = await f({ collectionId, documentId })
    console.log('dbSyncToProd: callable function res:', res)
    return res.data
  } catch (e) {
    console.warn('dbSyncToProd: error', e)
    return { error: _.get(e, 'message') }
  }
}
