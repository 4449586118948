import { useState, useEffect } from 'react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { HStack, Text, VStack, PopoverBody } from '@chakra-ui/react'
import { Column } from '@tanstack/react-table'

import { Button } from 'components/ui/Button'
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/Popover'
import { Checkbox } from 'components/ui/Checkbox'
import { FaceSignSession } from 'pages/Sessions'

interface ColumnFilterDropdownProps {
  column: Column<FaceSignSession, unknown>
  options: string[]
  colorMap?: Record<string, string>
}

export function ColumnFilterDropdown({ column, options, colorMap }: ColumnFilterDropdownProps) {
  const [selectedValues, setSelectedValues] = useState<Set<string>>(new Set(options))

  useEffect(() => {
    const filterValues = column?.getFilterValue() as string[] | undefined
    if (filterValues) {
      setSelectedValues(new Set(filterValues))
    } else {
      setSelectedValues(new Set(options))
    }
  }, [column, options])

  const handleValueToggle = (value: string) => {
    setSelectedValues((prev) => {
      const newSet = new Set(prev)
      if (newSet.has(value)) {
        newSet.delete(value)
      } else {
        newSet.add(value)
      }
      return newSet
    })
  }

  const handleSelectAll = () => {
    setSelectedValues(new Set(options))
  }

  const handleClearAll = () => {
    setSelectedValues(new Set())
  }

  const applyFilter = () => {
    const filterValues = Array.from(selectedValues)
    column?.setFilterValue(filterValues.length === options.length ? undefined : filterValues)
  }

  const allSelected = selectedValues.size === options.length
  const noneSelected = selectedValues.size === 0

  return (
    <Popover>
      <PopoverTrigger>
        <Button variant='ghost' size='sm' h='8' w='8' p='0' _hover={{ bg: 'gray.100' }}>
          <ChevronDownIcon boxSize={4} />
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverBody p={0}>
          <HStack justifyContent='space-between' mb={2}>
            <Button
              onClick={handleSelectAll}
              disabled={allSelected}
              variant='outline'
              size='sm'
            >
              Select All
            </Button>
            <Button
              onClick={handleClearAll}
              disabled={noneSelected}
              variant='outline'
              size='sm'
            >
              Clear All
            </Button>
          </HStack>
          <VStack spacing={2} align='start'>
            {options.map((option) => (
              <HStack
                key={option}
                spacing={2}
                w='full'
                p={1}
                rounded='md'
                cursor='pointer'
                _hover={{ bg: 'gray.100' }}
                onClick={() => handleValueToggle(option)}
              >
                <Checkbox
                  isChecked={selectedValues.has(option)}
                  onChange={() => handleValueToggle(option)}
                />
                <Text
                  fontSize='sm'
                  color={colorMap?.[option] ? `${colorMap[option]}.600` : 'inherit'}
                >
                  {option}
                </Text>
              </HStack>
            ))}
          </VStack>
          <Button mt={2} w='full' onClick={applyFilter}>
            Apply Filter
          </Button>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
