import * as React from 'react'
import { Checkbox as ChakraCheckbox, CheckboxProps } from '@chakra-ui/react'
import { CheckIcon } from '@chakra-ui/icons'

function CheckboxIcon({
  as: Element,
  ...rest
}: {
  as: React.ElementType,
  [key: string]: unknown
}) {
  const validProps = Object.keys(rest).reduce((acc, key) => {
    if (!['isIndeterminate', 'isChecked'].includes(key)) {
      acc[key] = rest[key]
    }
    return acc
  }, {} as Record<string, unknown>)
  return <Element {...validProps} />
}

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  return (
    <ChakraCheckbox
      ref={ref}
      icon={<CheckboxIcon as={CheckIcon} />}
      {...props}
    />
  )
})

Checkbox.displayName = 'Checkbox'

export { Checkbox }
