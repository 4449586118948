import {
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  Ref,
  useState
} from 'react'
import _ from 'lodash'
import {
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  ModalHeader,
  ModalFooter,
  Button,
  FormControl,
  VStack,
  ModalCloseButton,
  Input,
  Text
} from '@chakra-ui/react'
import { generateId } from 'controllers/db'
import Label from 'components/Label'
import { dbCreateVegasUser } from 'controllers/vegas'
import JsonInput from 'components/JsonInput'
import CodeEditor from 'components/CodeEditor'
import CodeEditorStandalone from 'components/CodeEditorStandalone'
import { TwoFactorT } from 'types/twoFactor'
import { DBT } from 'types/internal'
import SimpleInput from 'shared/components/SimpleInput'

export interface IAddVegasUserModal {
  open: () => void
}

type Props = {
  onUserCreated: (userId: string) => void
}

const AddVegasUserModal: ForwardRefRenderFunction<IAddVegasUserModal, Props> = (
  { onUserCreated },
  ref: Ref<unknown>
) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [name, setName] = useState('')

  useImperativeHandle(ref, () => ({
    open: () => {
      setName('')
      onOpen()
    }
  }))

  const onCreate = (u: Record<string, any>) => {
    if (u.name && !_.isEmpty(u.name)) {
      const userId = 'u_' + generateId()
      const user: Record<string, any> = {
        ...u,
        id: userId
      }
      dbCreateVegasUser(user)
      onUserCreated(userId)
      onClose()
    }
  }
  const validate = (v: string) => {
    try {
      const parsed = JSON.parse(v)
      if (!parsed.name || _.isEmpty(_.trim(parsed.name))) {
        return 'Name is required'
      }
    } catch (error) {
      return 'Invalid JSON format'
    }
    return null
  }

  const renderContent = () => {
    return (
      <VStack w='full' align='start'>
        <CodeEditorStandalone
          value={JSON.stringify({}, null, 2)}
          onChange={(v: string) => onCreate(JSON.parse(v))}
          validate={validate}
        />
      </VStack>
    )
  }

  const applyByName = (name: string) => {
    if (!_.isEmpty(name)) {
      const userId = 'u_' + generateId()
      const user: Record<string, any> = {
        id: userId,
        name
      }
      dbCreateVegasUser(user)
      onUserCreated(userId)
      onClose()
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='2xl' autoFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add user</ModalHeader>
        <ModalCloseButton />
        <ModalBody pt={4}>
          <VStack w='full' spacing={6}>
            <SimpleInput
              value={name}
              onChange={applyByName}
              variant={'outline'}
              h={8}
              placeholder='Enter name'
              autoFocus
            />
            <VStack w='full' spacing={8}>
              {renderContent()}
            </VStack>
          </VStack>
        </ModalBody>
        {/* <ModalFooter>
          <Button variant={'primary'} onClick={onCreate}>
            {'Add'}
          </Button>
        </ModalFooter> */}
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(AddVegasUserModal)
