import * as React from 'react'
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverProps,
  PopoverContentProps
} from '@chakra-ui/react'

const CustomPopover: React.FC<PopoverProps> = ({ children, ...props }) => (
  <Popover
    {...props}
  >
    {children}
  </Popover>
)

const CustomPopoverTrigger: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <PopoverTrigger>{children}</PopoverTrigger>
)

const CustomPopoverContent = React.forwardRef<
  React.ComponentRef<typeof PopoverContent>,
  PopoverContentProps & {
    align?: 'start' | 'center' | 'end'
    sideOffset?: number
  } & { children: React.ReactNode } & { style?: React.CSSProperties }
>(({ children, ...props }, ref) => (
  <PopoverContent
    ref={ref}
    bg='gray.50'
    color='gray.800'
    borderColor='gray.200'
    borderWidth='1px'
    borderRadius='md'
    boxShadow='md'
    _focus={{ outline: 'none' }}
    {...props}
  >
    <PopoverBody>{children}</PopoverBody>
  </PopoverContent>
))

CustomPopoverContent.displayName = 'CustomPopoverContent'

export { CustomPopover as Popover, CustomPopoverTrigger as PopoverTrigger, CustomPopoverContent as PopoverContent }
