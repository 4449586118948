import * as React from 'react'
import { Input as ChakraInput, InputProps as ChakraInputProps } from '@chakra-ui/react'

type InputProps = ChakraInputProps & {
  className?: string
  type?: string
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <ChakraInput
        type={type}
        ref={ref}
        {...props}
        size='md'
        borderRadius='md'
        borderColor='input'
        backgroundColor='background'
        paddingX={3}
        paddingY={2}
        fontSize='small'
        _placeholder={{ color: 'muted-foreground' }}
        _focus={{
          outline: 'none',
          ring: 2,
          ringColor: 'ring',
          ringOffset: 'background'
        }}
        _disabled={{
          cursor: 'not-allowed',
          opacity: 0.5
        }}
        className={className}
      />
    )
  }
)
Input.displayName = 'Input'

export { Input }
