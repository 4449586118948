import { FC, KeyboardEvent, FocusEvent } from 'react'
import CodeMirror from '@uiw/react-codemirror'
import { json } from '@codemirror/lang-json'
// import { TextEditorProps } from 'json-edit-react'
import { githubLight } from '@uiw/codemirror-theme-github'

type Props = {
  value: string
  onChange: (v: string) => void
  onKeyDown?: (e: KeyboardEvent<HTMLDivElement>) => void
  onFocus?: (e: FocusEvent<HTMLDivElement>) => void
  onBlur?: (e: FocusEvent<HTMLDivElement>) => void
}

const CodeEditor: FC<Props> = ({
  value,
  onChange,
  onKeyDown,
  onFocus,
  onBlur
}) => {
  return (
    <CodeMirror
      theme={githubLight}
      value={value}
      width='100%'
      minHeight='300px'
      extensions={[json()]}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  )
}

export default CodeEditor
