import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  limit,
  startAfter,
  endBefore,
  limitToLast,
  where
} from 'firebase/firestore'
import { algoliasearch } from 'algoliasearch'
import { SearchResponse } from '@algolia/client-search'

import { FaceSignSession } from 'pages/Sessions'
import { db } from 'controllers/db'
import { TwoFactorT } from 'types/twoFactor'
import config from 'src/config'

type PaginationDirection = 'next' | 'back'
type SessionUpdateCallback = (params: {
  data: FaceSignSession[],
  nextCursor?: string,
  backCursor?: string,
  atStart: boolean,
  atEnd: boolean
}) => void

const searchClient = algoliasearch(config.algoliaAppId, config.algoliaSearchKey)

const queryUnsubscribe: () => Promise<void> = async () => { }

const handleAlgoliaSearch = async (
  filterValue: string,
  limitBy: number,
  startTimestamp?: number,
  endTimestamp?: number,
  page?: number,
  onUpdate?: SessionUpdateCallback
) => {
  try {
    console.log('algolia sessions search: start')
    const numericFilters = []
    if (typeof startTimestamp === 'number') {
      console.log('algolia sessions search: from timestamp set')
      numericFilters.push(`createdAt>=${startTimestamp}`)
    }
    if (typeof endTimestamp === 'number') {
      console.log('algolia sessions search: to timestamp set')
      numericFilters.push(`createdAt<=${endTimestamp}`)
    }

    const response = await searchClient.search<FaceSignSession>({
      requests: [{
        indexName: config.algoliaSessionsIndex,
        query: filterValue,
        page,
        numericFilters: numericFilters.length > 0 ? numericFilters : undefined
      }]
    })
    console.log('algolia sessions search: result', response)

    const result = response.results[0] as SearchResponse<FaceSignSession>
    const hits = result?.hits || []
    const totalHits = result?.nbHits || 0
    console.log('algolia sessions search: result', totalHits)

    const data = hits.map((hit) => ({
      id: hit.objectID,
      ...hit
    })) as FaceSignSession[]

    const hasNextPage = totalHits > limitBy
    const nextCursor = hasNextPage ? '1' : undefined

    onUpdate?.({
      data,
      nextCursor,
      backCursor: undefined,
      atStart: true,
      atEnd: !hasNextPage
    })

  } catch (error) {
    console.error('Algolia search error:', error)
    onUpdate?.({
      data: [],
      nextCursor: undefined,
      backCursor: undefined,
      atStart: true,
      atEnd: true
    })
  }

  return () => { }
}

export const getSessionsWithCursor = async (
  limitBy: number,
  direction: PaginationDirection,
  cursor?: string,
  from?: Date,
  to?: Date,
  filterValue?: string,
  onUpdate?: SessionUpdateCallback
): Promise<() => void> => {
  if (queryUnsubscribe) queryUnsubscribe()
  if (filterValue) {
    const startTimestamp = from?.setHours(0, 0, 0, 0)
    const endTimestamp = to?.setHours(23, 59, 59, 999)
    return handleAlgoliaSearch(filterValue, limitBy, startTimestamp, endTimestamp, 0, onUpdate)
  }

  const startTimestamp = from?.setHours(0, 0, 0, 0)
  const endTimestamp = to ? new Date(to).setHours(23, 59, 59, 999) : undefined

  let q = query(
    collection(db, 'twoFactorInteractions'),
    orderBy('createdAt', 'desc'),
    limit(limitBy)
  )

  if (startTimestamp !== undefined) {
    q = query(q, where('createdAt', '>=', startTimestamp))
  }
  if (endTimestamp !== undefined) {
    q = query(q, where('createdAt', '<=', endTimestamp))
  }

  let atStart = false
  let atEnd = false

  if (cursor) {
    const cursorDoc = await getDoc(doc(db, 'twoFactorInteractions', cursor))
    const cursorTimestamp = cursorDoc.data()?.createdAt

    if (direction === 'next') {
      if (cursorTimestamp) {
        q = query(q, startAfter(cursorTimestamp))
      }
    } else {
      if (cursorTimestamp) {
        q = query(q, endBefore(cursorTimestamp), limitToLast(limitBy))
      }
    }
  }

  return onSnapshot(q, async (snapshot) => {
    const docs = snapshot.docs
    if (docs.length === 0) {
      onUpdate?.({
        data: [],
        nextCursor: undefined,
        backCursor: undefined,
        atStart: true,
        atEnd: true
      })
      return
    }

    const data = docs.map((doc) => ({
      id: doc.id,
      ...doc.data() as FaceSignSession
    })) as FaceSignSession[]

    const firstDoc = docs[0]
    const lastDoc = docs[docs.length - 1]

    const buildBaseQuery = () => {
      let base = query(
        collection(db, 'twoFactorInteractions'),
        orderBy('createdAt', 'desc')
      )
      if (startTimestamp !== undefined) {
        base = query(base, where('createdAt', '>=', startTimestamp))
      }
      if (endTimestamp !== undefined) {
        base = query(base, where('createdAt', '<=', endTimestamp))
      }
      return base
    }

    const prevQuery = query(
      buildBaseQuery(),
      endBefore(firstDoc.data().createdAt),
      limitToLast(1)
    )

    const nextQuery = query(
      buildBaseQuery(),
      startAfter(lastDoc.data().createdAt),
      limit(1)
    )

    const [prevSnapshot, nextSnapshot] = await Promise.all([
      getDocs(prevQuery),
      getDocs(nextQuery)
    ])

    atStart = prevSnapshot.empty
    atEnd = nextSnapshot.empty

    onUpdate?.({
      data,
      nextCursor: lastDoc?.id,
      backCursor: firstDoc?.id,
      atStart,
      atEnd
    })
  })
}

export const getSessionById = async (
  sessionId: string
): Promise<TwoFactorT.RecapT | undefined> => {
  try {
    const docRef = doc(db, 'twoFactorInteractions', sessionId)
    const docSnap = await getDoc(docRef)

    return docSnap.exists()
      ? docSnap.data() as TwoFactorT.RecapT
      : undefined
  } catch (error) {
    console.error('Error fetching session:', error)
    return undefined
  }
}
