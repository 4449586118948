import { DayPicker } from 'react-day-picker'
import { Box, IconButton } from '@chakra-ui/react'
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'

export const Calendar = ({ ...props }) => (
  <Box
    position='relative'
    sx={{
      '.rdp-months': {
        display: 'flex',
        flexDirection: 'row',
        gap: 4
      },
      '.rdp-month': {
        gap: 4
      },
      '.rdp-caption': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        pt: 1
      },
      '.rdp-month_caption': {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center'
      },
      '.rdp-caption_label': {
        fontSize: 'sm',
        fontWeight: 'medium',
        textAlign: 'center'
      },
      '.rdp-nav': {
        display: 'flex',
        gap: 1,
        alignItems: 'center',
        position: 'relative',
        h: 0,
        w: '100%'
      },
      '.rdp-button': {
        h: 7,
        w: 7,
        p: 0,
        opacity: 0.5,
        _hover: { opacity: 1 }
      },
      '.rdp-button_previous': {
        position: 'absolute',
        top: 5,
        left: 1
      },
      '.rdp-button_next': {
        position: 'absolute',
        top: 5,
        right: 1
      },
      '.rdp-table': {
        w: 'full',
        borderCollapse: 'collapse'
      },
      '.rdp-head_row': {
        display: 'flex'
      },
      '.rdp-head_cell': {
        color: 'muted',
        rounded: 'md',
        w: 8,
        fontSize: '0.8rem'
      },
      '.rdp-row': {
        display: 'flex',
        w: 'full',
        mt: 2
      },
      '.rdp-cell': {
        p: 0,
        textAlign: 'center',
        fontSize: 'sm',
        _focusWithin: { position: 'relative', zIndex: 20 }
      },
      '.rdp-day': {
        h: 4,
        w: 4,
        p: 0,
        fontWeight: 'normal',
        borderRadius: 'md',
        fontSize: 'sm',
        _today: {
          border: '2px solid',
          borderColor: 'zinc.500'
        }
      },
      '.rdp-day_button': {
        w: 9,
        h: 9,
        borderRadius: 'md',
        borderColor: 'transparent',
        _hover: {
          bg: 'zinc.300'
        }
      },
      '.rdp-range_start': {
        bg: 'zinc.900',
        color: 'white',
        _hover: { bg: 'zinc.600' },
        '> button': {
          bg: 'zinc.900',
          borderColor: 'transparent',
          borderRadius: 'md'
        }
      },
      '.rdp-range_end': {
        bg: 'zinc.900',
        color: 'white',
        _hover: { bg: 'zinc.600' },
        '> button': {
          bg: 'zinc.900',
          borderColor: 'transparent',
          borderRadius: 'md'
        }
      },
      '.rdp-today': {
        bg: 'zinc.200',
        color: 'inherit'
      },
      '.rdp-day_disabled': {
        color: 'muted',
        opacity: 0.5
      }
    }}
  >
    <DayPicker
      showOutsideDays
      components={{
        PreviousMonthButton: ({ ...props }) => (
          <IconButton
            aria-label='Previous month'
            icon={<ChevronLeftIcon />}
            variant='outline'
            size='sm'
            {...props}
          />
        ),
        NextMonthButton: ({ ...props }) => (
          <IconButton
            aria-label='Next month'
            icon={<ChevronRightIcon />}
            variant='outline'
            size='sm'
            {...props}
          />
        )
      }}
      {...props}
    />
  </Box>
)