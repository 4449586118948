import * as React from 'react'
import * as SelectPrimitive from '@radix-ui/react-select'
import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import { Box, Icon } from '@chakra-ui/react'

const Select = SelectPrimitive.Root

const SelectGroup = SelectPrimitive.Group

const SelectValue = SelectPrimitive.Value

const SelectTrigger = React.forwardRef<
  React.ComponentRef<typeof SelectPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger> & { className?: string }
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Trigger
    ref={ref}
    className={className}
    asChild
  >
    <Box
      as='button'
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '2.5rem',
        width: '100%',
        borderRadius: 'md',
        border: '1px solid',
        borderColor: 'gray.200',
        bg: 'white',
        px: 3,
        py: 2,
        fontSize: 'sm',
        cursor: 'pointer',
        _focus: {
          outline: 'none',
          ring: '2px solid #3182ce'
        },
        _disabled: {
          cursor: 'not-allowed',
          opacity: 0.5
        }
      }}
      {...props}
    >
      {children}
      <ChevronDownIcon boxSize={4} />
    </Box>
  </SelectPrimitive.Trigger>
))
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName

const SelectScrollUpButton = React.forwardRef<
  React.ComponentRef<typeof SelectPrimitive.ScrollUpButton>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollUpButton> & { className?: string }
>(({ ...props }, ref) => (
  <SelectPrimitive.ScrollUpButton
    ref={ref}
    {...props}
  >
    <Icon as={ChevronUpIcon} boxSize={4} />
  </SelectPrimitive.ScrollUpButton>
))
SelectScrollUpButton.displayName = SelectPrimitive.ScrollUpButton.displayName

const SelectScrollDownButton = React.forwardRef<
  React.ComponentRef<typeof SelectPrimitive.ScrollDownButton>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollDownButton> & { className?: string }
>(({ ...props }, ref) => (
  <SelectPrimitive.ScrollDownButton
    ref={ref}
    {...props}
  >
    <Icon as={ChevronDownIcon} boxSize={4} />
  </SelectPrimitive.ScrollDownButton>
))
SelectScrollDownButton.displayName = SelectPrimitive.ScrollDownButton.displayName

const SelectContent = React.forwardRef<
  React.ComponentRef<typeof SelectPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content> & {
    position?: 'popper' | 'static'
    className?: string
  }
>(({ children, ...props }, ref) => (
  <SelectPrimitive.Portal>
    <SelectPrimitive.Content
      ref={ref}
      {...props}
      style={{ zIndex: 100 }}
    >
      <Box
        sx={{
          position: 'relative',
          zIndex: 50,
          maxHeight: '24rem',
          minWidth: '8rem',
          overflow: 'hidden',
          borderRadius: 'md',
          bg: 'white',
          shadow: 'md',
          _focus: {
            outline: 'none'
          },
          _dataStateOpen: {
            animation: 'fade-in 0.2s ease-out'
          },
          _dataStateClosed: {
            animation: 'fade-out 0.2s ease-out'
          }
        }}
      >
        <SelectScrollUpButton />
        <SelectPrimitive.Viewport
          style={{
            padding: '0.25rem',
            height: 'auto',
            minWidth: '100%'
          }}
        >
          {children}
        </SelectPrimitive.Viewport>
        <SelectScrollDownButton />
      </Box>
    </SelectPrimitive.Content>
  </SelectPrimitive.Portal>
))
SelectContent.displayName = SelectPrimitive.Content.displayName

const SelectLabel = React.forwardRef<
  React.ComponentRef<typeof SelectPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label> & { className?: string }
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Label
    ref={ref}
    className={className}
    style={{
      paddingTop: '0.375rem',
      paddingBottom: '0.375rem',
      paddingLeft: '2rem',
      paddingRight: '0.5rem',
      fontSize: '0.875rem',
      fontWeight: 600
    }}
    {...props}
  />
))
SelectLabel.displayName = SelectPrimitive.Label.displayName

const SelectItem = React.forwardRef<
  React.ComponentRef<typeof SelectPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item> & { className?: string }
>(({ children, ...props }, ref) => (
  <SelectPrimitive.Item
    ref={ref}
    {...props}
  >
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        width: 'full',
        cursor: 'default',
        select: 'none',
        alignItems: 'center',
        py: 1.5,
        pl: 8,
        pr: 2,
        fontSize: 'sm',
        borderRadius: 'sm',
        _focus: {
          bg: 'accent',
          color: 'accent-foreground'
        },
        _disabled: {
          pointerEvents: 'none',
          opacity: 0.5
        }
      }}
    >
      <Box sx={{ position: 'absolute', left: 2 }}>
        <SelectPrimitive.ItemIndicator>
          <CheckIcon boxSize={4} />
        </SelectPrimitive.ItemIndicator>
      </Box>
      <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
    </Box>
  </SelectPrimitive.Item>
))
SelectItem.displayName = SelectPrimitive.Item.displayName

const SelectSeparator = React.forwardRef<
  React.ComponentRef<typeof SelectPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator> & { className?: string }
>(({ ...props }, ref) => (
  <SelectPrimitive.Separator
    ref={ref}
    {...props}
  >
    <Box
      sx={{
        mx: -1,
        my: 1,
        height: '1px',
        backgroundColor: 'gray.300'
      }}
    />
  </SelectPrimitive.Separator>
))
SelectSeparator.displayName = SelectPrimitive.Separator.displayName

export {
  Select,
  SelectGroup,
  SelectValue,
  SelectTrigger,
  SelectContent,
  SelectLabel,
  SelectItem,
  SelectSeparator,
  SelectScrollUpButton,
  SelectScrollDownButton
}