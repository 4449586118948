import { FC, useState, useEffect, useMemo } from 'react'
import { Button, Flex, Text, VStack } from '@chakra-ui/react'
import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import Label from 'components/Label'
import { DBT } from 'types/internal'
import { dbFetchAvatars, dbFetchHeyGenAvatars } from 'controllers/settings'
import _ from 'lodash'

type Props = {
  value?: string
  onSelect: (avatarId: string) => void
}

const AvatarSelect: FC<Props> = ({ value, onSelect }) => {
  const [avatars, setAvatars] = useState<DBT.HGAvatarT[]>([])
  const [processing, setProcessing] = useState(false)

  useEffect(() => {
    const run = async () => {
      const newAvatars = await dbFetchAvatars()
      if (newAvatars) {
        setAvatars(_.sortBy(newAvatars, 'created_at'))
      }
    }
    run()
  }, [])

  const selectedAvatar = useMemo(() => {
    return _.find(avatars, a => a.avatar_id === value)
  }, [avatars, value])

  const fetchHeyGenAvatars = async () => {
    setProcessing(true)
    try {
      const res = await dbFetchHeyGenAvatars()
      if (res) {
        setAvatars(res)
      }
    } catch (error) {
      console.error('Error fetching HeyGen avatars:', error)
    }
    setProcessing(false)
  }

  const renderAvatarOption = (avatar: DBT.HGAvatarT) => (
    <MenuItem
      key={avatar.avatar_id}
      value={avatar.avatar_id}
      onClick={() => onSelect(avatar.avatar_id)}
    >
      <Flex justifyContent='space-between' alignItems='center'>
        <Flex alignItems='baseline' gap={4}>
          <Text>{avatar.avatar_id}</Text>
        </Flex>
      </Flex>
    </MenuItem>
  )

  return (
    <VStack w='full' align={'flex-start'}>
      <Label>Avatar ID</Label>
      <Flex alignItems='center' gap={2} w='full'>
        <Menu placement='bottom-start'>
          <MenuButton
            as={Button}
            size='sm'
            variant={'outline'}
            rightIcon={<ChevronDownIcon />}
            w='full'
          >
            {selectedAvatar
              ? renderAvatarOption(selectedAvatar)
              : 'Select avatar'}
          </MenuButton>
          <MenuList maxH={'md'} overflowY='auto'>
            {avatars.map(renderAvatarOption)}
          </MenuList>
        </Menu>
      </Flex>
      <Button
        variant={'link'}
        size='xs'
        onClick={fetchHeyGenAvatars}
        isLoading={processing}
      >
        fetch HeyGen avatars
      </Button>
    </VStack>
  )
}

export default AvatarSelect
