import { forwardRef } from 'react'
import { Button as ChakraButton, ButtonProps as ChakraButtonProps } from '@chakra-ui/react'

export const buttonVariants = {
  default: {
    bg: 'zinc.900',
    color: 'white',
    border: '1px solid',
    borderColor: 'gray.200',
    _hover: { bg: 'zinc.950', opacity: 0.9 },
    _disabled: {
      opacity: 0.5,
      pointerEvents: 'none',
      borderColor: 'gray.300'
    }
  },
  destructive: {
    bg: 'red.500',
    color: 'white',
    border: '1px solid',
    borderColor: 'red.500',
    _hover: { bg: 'red.600', opacity: 0.9 },
    _disabled: {
      opacity: 0.5,
      pointerEvents: 'none',
      borderColor: 'red.300'
    }
  },
  outline: {
    border: '1px solid',
    borderColor: 'gray.200',
    bg: 'white',
    _hover: { bg: 'gray.50', color: 'gray.800' },
    _disabled: {
      opacity: 0.5,
      pointerEvents: 'none',
      borderColor: 'gray.300'
    }
  },
  secondary: {
    bg: 'gray.100',
    color: 'gray.800',
    border: '1px solid',
    borderColor: 'gray.200',
    _hover: { bg: 'gray.200', opacity: 0.8 },
    _disabled: {
      opacity: 0.5,
      pointerEvents: 'none',
      borderColor: 'gray.300'
    }
  },
  ghost: {
    bg: 'transparent',
    _hover: { bg: 'gray.50', color: 'gray.800' },
    _disabled: { opacity: 0.5, pointerEvents: 'none' }
  },
  link: {
    color: 'zinc.900',
    textDecoration: 'underline',
    _hover: { textDecoration: 'underline', color: 'zinc.950' },
    _disabled: { opacity: 0.5, pointerEvents: 'none' }
  }
}


const buttonSizes = {
  default: { h: '40px', px: '16px', py: '8px', fontSize: 'sm' },
  sm: { h: '36px', px: '12px', fontSize: 'sm', borderRadius: 'md' },
  lg: { h: '44px', px: '24px', fontSize: 'md', borderRadius: 'md' },
  icon: { h: '40px', w: '40px', p: 0 }
}

interface CustomButtonProps extends ChakraButtonProps {
  variant?: keyof typeof buttonVariants
  size?: keyof typeof buttonSizes
  asChild?: boolean
}

const Button = forwardRef<HTMLButtonElement, CustomButtonProps>(
  ({ variant = 'default', size = 'default', disabled, asChild, ...props }, ref) => {
    const styles = {
      ...(buttonVariants[variant] || buttonVariants.default),
      ...(buttonSizes[size] || buttonSizes.default)
    }

    return (
      <ChakraButton
        {...styles}
        as={asChild ? 'span' : 'button'}
        ref={ref}
        isDisabled={disabled}
        {...props}
      />
    )
  }
)

Button.displayName = 'Button'

export { Button }
