import { Textarea, Button, VStack, useToast, HStack } from '@chakra-ui/react'
import ResizeTextarea from 'react-textarea-autosize'
import { useState, FC, useEffect, useRef } from 'react'
import Label from 'components/Label'
import { Unsubscribe } from 'firebase/firestore'
import { DBT } from 'types/internal'
import _ from 'lodash'
import TemperatureInput from 'components/TemperatureInput'
import JsonSchemeInput from 'components/JsonSchemeInput'
import NavBar from 'components/NavBar'
import { dbSubscribeToLiveness, dbUpdateLiveness } from 'controllers/liveness'
import AiPlatformSelect from 'pages/settings/AIPlatformSelect'
import AIModels from 'pages/settings/AIModels'
import { dbSyncToProd } from 'controllers/vegas'
import config from 'src/config'

type Props = {}

const emptyPrompt = {
  prompt: '',
  temperature: 0,
  maxTokens: 1500
}

const Liveness: FC<Props> = () => {
  const [conf, setConf] = useState<DBT.AIPromptConfT | null>(emptyPrompt)
  const [savedConf, setSavedConf] = useState<DBT.AIPromptConfT | null>(null)
  const unsubscribeRef = useRef<Unsubscribe | null>(null)
  const toast = useToast()
  const [isSyncing, setIsSyncing] = useState(false)

  useEffect(() => {
    if (unsubscribeRef.current) {
      unsubscribeRef.current()
    }
    unsubscribeRef.current = dbSubscribeToLiveness(conf => {
      setConf(conf)
      setSavedConf(conf)
    })
  }, [])

  const onSave = () => {
    if (conf) {
      try {
        const newConf = { ...conf }
        if (newConf.jsonScheme) {
          JSON.parse(newConf.jsonScheme)
        }
        dbUpdateLiveness(conf)
      } catch (e) {
        toast({
          title: 'Error',
          description: 'Failed to save configuration, check JSON scheme',
          status: 'error',
          duration: 3000,
          isClosable: true
        })
      }
    }
  }

  const onChange = (params: Partial<DBT.AIPromptConfT>) => {
    if (conf) {
      setConf({ ...conf, ...params })
    }
  }

  const renderTemperatureInput = () => {
    if (!conf) return null
    const value = conf.temperature || 0
    return (
      <TemperatureInput
        value={value}
        onChange={value => setConf({ ...conf, temperature: value })}
      />
    )
  }

  const syncToProd = async () => {
    try {
      setIsSyncing(true)
      await dbSyncToProd('settings', 'liveness')
    } catch (e) {
      console.error('sync to prod error', e)
    }
    setIsSyncing(false)
  }

  const actions = (
    <HStack spacing={4}>
      {config.isDev && (
        <Button
          size='sm'
          variant='link'
          colorScheme='white'
          isLoading={isSyncing}
          onClick={syncToProd}
        >
          Sync to Prod
        </Button>
      )}
      <Button
        variant={'outline'}
        colorScheme='white'
        onClick={onSave}
        isDisabled={_.isEqual(conf, savedConf)}
        size='sm'
      >
        Save
      </Button>
    </HStack>
  )

  return (
    <VStack w='full' h='full' bg='white' overflow={'hidden'}>
      <NavBar title={'Liveness Detection Prompt'} actions={actions} />
      <VStack w='full' overflow={'auto'} p={6}>
        <VStack w='full' spacing={6}>
          <VStack w='full' align={'flex-start'} spacing={2}>
            <Label>Prompt</Label>
            <Textarea
              placeholder='Enter prompt here'
              value={_.get(conf, 'prompt', '')}
              onChange={e => onChange({ prompt: e.target.value })}
              fontSize={'sm'}
              minH='unset'
              overflow='hidden'
              w='100%'
              resize='none'
              minRows={5}
              as={ResizeTextarea}
            />
          </VStack>
          <JsonSchemeInput
            value={conf?.jsonScheme || ''}
            onChange={(v: string) => onChange({ jsonScheme: v })}
          />
          {renderTemperatureInput()}
          <AiPlatformSelect
            value={conf?.aiPlatform}
            onChange={v => onChange({ aiPlatform: v })}
          />
          <AIModels
            value={conf?.models}
            onChange={v => onChange({ models: v })}
          />
        </VStack>
      </VStack>
    </VStack>
  )
}

export default Liveness
