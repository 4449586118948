import { useCallback, useEffect, useState, useRef } from 'react'
import { Box, Text } from '@chakra-ui/react'
import _ from 'lodash'
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'

import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/Select'
import { Button } from 'components/ui/Button'
import { SessionsTable } from 'pages/sessions/SessionsTable'
import { getSessionsWithCursor } from 'controllers/sessions'
import { DateRange } from 'react-day-picker'

export type FaceSignSession = {
  sessionId: string
  dateTime: string
  email: string | null
  userName: string
  env: string,
  avatar: string
  appVersion: string
  demoVersion: string
  interactionId: string
  verificationId: string
  platform: string
  browser: string
  location: string
  language: string
  photoThumbnail: string
  isNewUser: boolean
  isDeepfake: boolean
  isLivePerson: boolean
  ageRange: string
  source: string
  sessionCompleted: boolean
  errorMessage: string | null
}

export default function Home() {
  const [data, setData] = useState<FaceSignSession[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)
  const [pageSize, setPageSize] = useState<number>(20)
  const [dateRange, setDateRange] = useState<DateRange>()
  const [cursors, setCursors] = useState<{ next?: string, previous?: string, atStart?: boolean, atEnd?: boolean }>({})
  const [filterValue, setFilterValue] = useState<string>('')
  const unsubscribeRef = useRef<() => void>(() => { })

  useEffect(() => {
    const fetchData = async () => {
      if (!error) {
        console.log('data fetch started')
        getData('next')
      }
    }

    fetchData()

    return () => {
      if (unsubscribeRef.current) {
        unsubscribeRef.current()
      }
    }
  }, [pageSize, dateRange, filterValue])

  const getData = async (direction: 'back' | 'next') => {
    setLoading(true)
    setError(null)
    const cursor = direction === 'next' ? cursors.next : cursors.previous

    if (unsubscribeRef.current) {
      unsubscribeRef.current()
    }

    try {
      const unsubscribe = await getSessionsWithCursor(
        pageSize,
        direction,
        cursor,
        dateRange?.from,
        dateRange?.to,
        filterValue,
        ({ data, nextCursor, backCursor, atStart, atEnd }) => {
          try {
            const formattedSessions = formatSessionsObject(data)
            setData(formattedSessions)
            setCursors({
              next: nextCursor,
              previous: backCursor,
              atStart,
              atEnd
            })

            setLoading(false)
          } catch (e) {
            console.error('error processing session data', e)
            setError('Error processing sessions data')
          }
        }
      )

      unsubscribeRef.current = unsubscribe
    } catch (error) {
      console.error('Error setting up listener:', error)
      setError('Error initializing data connection')
    }
  }

  const handleDateRangeChange = (date: DateRange | undefined) => {
    setCursors({})
    setDateRange(date)
  }

  const formatSessionsObject = useCallback((sObject: unknown[]): FaceSignSession[] =>
    _.map(sObject, s => ({
      sessionId: _.get(s, 'id', '-'),
      dateTime: _.has(s, 'createdAt') ? new Date(_.get(s, 'createdAt')).toUTCString() : '',
      email: _.get(s, 'email', '-'),
      userName: _.get(s, 'userName', '-'),
      env: _.get(s, 'isDev', false) ? 'INT' : 'PROD',
      avatar: _.get(s, 'avatar', '-'),
      appVersion: _.get(s, 'version', '-'),
      demoVersion: '-',
      interactionId: _.get(s, 'id', '-'),
      verificationId: _.get(s, 'verificationId', '-'),
      platform: _.get(s, 'deviceDetails.platform', '-'),
      browser: _.get(s, 'deviceDetails.browser', '-'),
      location: `${_.get(s, 'location.city.names.en', '-')}, ${_.get(s, 'location.country.names.en', '-')}`,
      language: _.get(s, 'lang', '-'),
      photoThumbnail: _.get(s, 'userAvatarUrl', ''),
      isNewUser: !_.get(s, 'isNotNew'),
      isLivePerson: _.get(s, 'livenessDetected', false),
      ageRange: `${_.get(s, 'aiAnalysisFirst.ageMin', '?')} – ${_.get(s, 'aiAnalysisFirst.ageMax', '?')}`,
      source: '-',
      sessionCompleted: _.get(s, 'completed', false),
      isDeepfake: false,
      errorMessage: ''
    })), [data])

  if (error) {
    return (
      <Box>
        <Text>
          {error}
        </Text>
      </Box>
    )
  }

  return (
    <Box h='full' display='flex' flexDir='column'>
      <Box flexGrow='1' overflow='hidden'>
        <SessionsTable
          data={data}
          loading={loading}
          setDateRange={handleDateRangeChange}
          pageSize={pageSize}
          filterValue={filterValue}
          setFilterValue={setFilterValue}
        />
      </Box>
      <Box borderTopColor='gray.200' px={{ lg: '4', base: '6' }} py='3'>
        <Box maxW='100%' overflowX='auto'>
          <Box display='flex' alignItems='center' justifyContent='space-between'>
            <Box display='flex' flexGrow={0} flexShrink={0} alignItems='center' gap='2'>
              <Button
                variant='outline'
                size='sm'
                onClick={() => getData('back')}
                disabled={loading || !!error || cursors.atStart}
              >
                <ChevronLeftIcon h='4' w='4' />
              </Button>
              <Button
                variant='outline'
                size='sm'
                onClick={() => getData('next')}
                disabled={loading || !!error || cursors.atEnd}
              >
                <ChevronRightIcon h='4' w='4' />
              </Button>
            </Box>
            <Box width='fit-content'>
              <Select
                value={pageSize.toString()}
                onValueChange={(value) => {
                  setPageSize(Number(value))
                }}
              >
                <SelectTrigger>
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <SelectItem key={pageSize} value={pageSize.toString()}>
                      {pageSize}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}