import { collection, doc, getDoc } from 'firebase/firestore'
import { db } from 'controllers/db'
import { DBT } from 'types/internal'
import { httpsCallable } from 'firebase/functions'
import { functions } from 'controllers/db'

export const dbFetchAvatars = async () => {
  try {
    const ref = doc(collection(db, 'settings'), 'avatars')
    const avatarsSN = await getDoc(ref)
    const avatarsDoc = avatarsSN.data()
    if (avatarsDoc) {
      const avatars = avatarsDoc.avatars as DBT.HGAvatarT[]
      return avatars
    } else {
      return null
    }
  } catch (e) {
    console.log('fetchAvatars error', e)
  }
}

export const dbFetchHeyGenAvatars = async () => {
  try {
    console.log('dbSyncAvatars start')
    const f = httpsCallable(functions, 'fetchHeyGenAvatars')
    const res = await f()
    console.log('callable function res:', res)
    return res.data as DBT.HGAvatarT[]
  } catch (e) {
    console.warn('error', e)
    return null
  }
}
