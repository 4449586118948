// Start of Selection
import { FC } from 'react'
import { Input } from '@chakra-ui/react'

type Props = {
  onSelect: (files: File[]) => void
}

const ImagePaste: FC<Props> = ({ onSelect }) => {
  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const items = e.clipboardData.items
    const files: File[] = []
    const allowedTypes = ['image/jpeg', 'image/png', 'image/heic']
    for (let i = 0; i < items.length; i++) {
      const item = items[i]
      if (allowedTypes.includes(item.type)) {
        const file = item.getAsFile()
        if (file) {
          files.push(file)
        }
      }
    }
    if (files.length > 0) {
      onSelect(files)
    }
  }

  return (
    <Input
      size='sm'
      rounded={'md'}
      w={{ base: 'full', lg: 'lg' }}
      placeholder='Click here and paste an image from clipboard'
      onPaste={handlePaste}
    />
  )
}

export default ImagePaste
