import { Button, HStack, Stack, Text, VStack } from '@chakra-ui/react'
import NavBar from 'components/NavBar'
import UsersSearch from 'components/UsersSearch'
import { dbSaveVegasUser, dbSubscribeToUser } from 'controllers/vegas'
import { Unsubscribe } from 'firebase/firestore'
import { useState, useRef, useEffect, useMemo } from 'react'
// import _ from 'lodash'

import AddVegasUserModal, { IAddVegasUserModal } from 'modals/AddVegasUserModal'
import SearchByPhoto from 'pages/users/SearchByPhoto'
import { isDev } from 'src/config'
import UserPhotos from 'pages/users/UserPhotos'
import { DBT } from 'types/internal'
import JsonInput from 'components/JsonInput'
import UserProfile from 'pages/users/UserProfile'
import UserParamsList from 'pages/users/UserParamsList'
import { useNavigate, useParams } from 'react-router'
import CodeEditorStandalone from 'components/CodeEditorStandalone'
import _ from 'lodash'

const Users = () => {
  const { userId } = useParams()
  const [user, setUser] = useState<DBT.VegasUserT>()
  // const [savedUser, setSavedUser] = useState<object>()
  const unsubscribeRef = useRef<Unsubscribe | null>(null)
  const addVegasUserModalRef = useRef<IAddVegasUserModal>(null)
  const navigate = useNavigate()
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (unsubscribeRef.current) {
      unsubscribeRef.current()
    }
    if (userId) {
      unsubscribeRef.current = dbSubscribeToUser(userId, u => {
        setUser(u as DBT.VegasUserT)
        // setSavedUser(u)
      })

      setTimeout(() => {
        if (containerRef.current) {
          console.log('FOCUS')
          containerRef.current.focus()
        }
      }, 1)
    }
  }, [userId])

  const clearUser = useMemo(() => {
    return _.omit(user, ['photoUrl', 'photos', 'faces', 'id'])
  }, [user])

  const onSelectUser = (userId: string) => {
    // console.log('onSelectUser', userId)
    navigate(`/users/${userId}`)
    // setUserId(userId)
  }

  const renderSearch = () => {
    return (
      <HStack align={'center'} spacing={6} w='full' maxW='2xl'>
        <UsersSearch onSelect={onSelectUser} />
        <SearchByPhoto onUserFound={onSelectUser} />
      </HStack>
    )
  }

  const handleEdit = (key: string, newValue: string) => {
    // console.log('handleEdit', key, newValue)
    dbSaveVegasUser({ ...user, [key]: newValue } as DBT.VegasUserT)
  }

  const handleDelete = (key: string) => {
    const newValue = { ...user }
    delete newValue[key]
    dbSaveVegasUser(newValue as DBT.VegasUserT)
  }

  const handleAdd = (newKey: string, newValue: string) => {
    if (
      newKey &&
      newValue &&
      newKey !== 'id' &&
      newKey !== 'photoUrl' &&
      newKey !== 'photos' &&
      newKey !== 'faces'
    ) {
      dbSaveVegasUser({
        ...user,
        [_.camelCase(newKey)]: newValue
      } as DBT.VegasUserT)
    }
  }

  const renderUserParams = () => {
    if (user) {
      return (
        <UserParamsList
          user={clearUser}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          handleAdd={handleAdd}
        />
      )
    }
  }

  // const onSave = () => {
  //   if (user) {
  //     dbSaveVegasUser(user)
  //   }
  // }

  const onUserCreated = (userId: string) => {
    navigate(`/users/${userId}`)
  }

  const renderPhotos = () => {
    if (user) {
      return <UserPhotos user={user} />
    }
  }

  const actions = (
    <HStack>
      <Button
        variant={'primary'}
        size='sm'
        onClick={() => addVegasUserModalRef.current?.open()}
      >
        Add user
      </Button>
      {/* <Button
        variant={'outline'}
        colorScheme='white'
        onClick={onSave}
        isDisabled={_.isEqual(user, savedUser)}
        size='sm'
      >
        Save
      </Button> */}
    </HStack>
  )

  const onUpdateUserByJson = (upd: Record<string, any>) => {
    console.log('onUpdateUserByJson', upd)
    const deletedKeys = _.difference(_.keys(clearUser), _.keys(upd))
    console.log('deleted keys', deletedKeys)
    const newUser = {
      ...user,
      ...upd
    }
    const omitedUser = _.isEmpty(deletedKeys)
      ? newUser
      : _.omit(newUser, deletedKeys)
    dbSaveVegasUser(omitedUser as DBT.VegasUserT)
  }

  const validate = (v: string) => {
    try {
      const parsed = JSON.parse(v)
      if (!parsed.name || _.isEmpty(_.trim(parsed.name))) {
        return 'Name is required'
      }
    } catch (error) {
      return 'Invalid JSON format'
    }
    return null
  }

  const renderJsonInput = () => {
    if (user) {
      return (
        <CodeEditorStandalone
          value={JSON.stringify(clearUser, null, 2)}
          onChange={v => onUpdateUserByJson(JSON.parse(v))}
          validate={validate}
        />
      )
    }
  }

  const onUserDeleted = () => {
    navigate('/users')
    setUser(undefined)
    // setSavedUser(undefined)
  }

  const renderNavBarContent = () => {
    return renderSearch()
  }

  if (isDev) {
    return (
      <VStack
        w='full'
        h='full'
        bg='white'
        overflow={'hidden'}
        pt={16}
        spacing={6}
      >
        <Text>Managing users is available in production environment only</Text>
        <Button
          variant='primary'
          onClick={() =>
            window.open('https://admin.facesign.ai/vegas_users', '_blank')
          }
        >
          Open prod admin panel
        </Button>
      </VStack>
    )
  } else {
    return (
      <VStack w='full' h='full' bg='white' overflow={'hidden'}>
        <NavBar title='Recognized Users' actions={actions}>
          {renderNavBarContent()}
        </NavBar>

        <VStack
          w='full'
          overflow={'auto'}
          p={{ base: 2, lg: 6 }}
          h='full'
          flex={1}
          spacing={6}
          ref={containerRef}
        >
          {/* <VStack w='full' spacing={10} flex={1}> */}
          {user && <UserProfile user={user} onUserDeleted={onUserDeleted} />}
          {renderUserParams()}
          {renderJsonInput()}
          {renderPhotos()}
          {/* </VStack> */}
        </VStack>
        <AddVegasUserModal
          ref={addVegasUserModalRef}
          onUserCreated={onUserCreated}
        />
      </VStack>
    )
  }
}

export default Users
