import { useToast, VStack, HStack, Text, Link } from '@chakra-ui/react'
import FilesPickerButton from 'components/FilesPickerButton'
import Label from 'components/Label'
import { generateId } from 'controllers/db'
import { FC, useMemo, useState } from 'react'
import { FileWithPath } from 'react-dropzone/.'
import { DBT } from 'types/internal'
import { saveFileObject } from 'controllers/storage'
import _ from 'lodash'
import { addVegasUserPhotos } from 'controllers/vegas'
import ImagePaste from 'components/ImagePaste'
import UserPhoto from 'pages/users/userPhotos/UserPhoto'
import { ExternalLinkIcon } from '@chakra-ui/icons'

type Props = {
  user: DBT.VegasUserT
}

const UserPhotos: FC<Props> = ({ user }) => {
  const [processingFiles, setProcessingFiles] = useState(false)
  const toast = useToast()

  const sortedFaces = useMemo(
    () => _.sortBy(user.faces, 'createdAt'),
    [user.faces]
  )

  const uploadFileToStorage = async (f: FileWithPath | File) => {
    if (user && user.id) {
      const fileExt = _.last(_.split(f.name, '.'))
      const filename = `${user.id}_${generateId()}.${fileExt}`
      const storagePath = `vegasUsers/${filename}`
      const storageFile = await saveFileObject(f, storagePath)
      return storageFile
    }
  }

  const resizeImage = async (file: FileWithPath | File): Promise<File> => {
    const image = await createImageBitmap(file)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    const maxDimension = 1024
    const scale = Math.min(
      maxDimension / image.width,
      maxDimension / image.height,
      1
    )
    canvas.width = image.width * scale
    canvas.height = image.height * scale
    if (ctx) {
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height)
    }
    return new Promise<File>(resolve => {
      canvas.toBlob(blob => {
        resolve(new File([blob!], file.name, { type: file.type }))
      }, file.type)
    })
  }

  const onFilesPicked = async (files: File[] | FileWithPath[]) => {
    console.log('on files picked', files)

    if (files.length > 0 && user && user.id) {
      try {
        setProcessingFiles(true)
        const resizedFiles = await Promise.all(_.map(files, resizeImage))

        const storageFiles = await Promise.all(
          _.map(resizedFiles, uploadFileToStorage)
        )
        console.log('storageFiles', storageFiles)
        const urls = _.compact(_.map(storageFiles, sf => sf?.url))
        const res = await addVegasUserPhotos(user.id, urls)
        const photoProcessingLogs = _.get(res, 'photoProcessingLogs')
        // if (res && _.has(res, 'success') && res.success) {
        //   toast({
        //     title: 'Success',
        //     description: `${_.get(res, 'added', 0)} images successfully added`,
        //     status: 'success',
        //     duration: 3000,
        //     isClosable: true
        //   })
        // } else {
        //   toast({
        //     title: 'Operation failed',
        //     // description: _.get(res, 'message', ''),
        //     status: 'error',
        //     duration: 3000,
        //     isClosable: true
        //   })
        // }
        if (photoProcessingLogs) {
          _.forEach(photoProcessingLogs, (r, i) => {
            const success = _.get(r, 'success', false)
            const title = success ? 'Success' : 'Operation failed'
            const status = success ? 'success' : 'error'
            let description = (
              <VStack w='full' align='start' spacing={2}>
                <Link isExternal href={_.get(r, 'sourceUrl')}>
                  <ExternalLinkIcon mx='2px' /> open photo
                </Link>
              </VStack>
            )
            if (!success) {
              description = (
                <VStack w='full' align={'start'} spacing={2}>
                  <Link isExternal href={_.get(r, 'sourceUrl')}>
                    <ExternalLinkIcon mx='2px' /> open photo
                  </Link>
                  <Text>ERROR: {_.get(r, 'error')}</Text>
                </VStack>
              )
            }
            toast({
              title,
              description,
              status,
              variant: 'subtle',
              duration: 5000,
              isClosable: true
            })
          })
        }
        setProcessingFiles(false)
      } catch (e) {
        console.error(e)
        setProcessingFiles(false)
        toast({
          title: 'Error',
          description: 'Failed to upload files, check console logs',
          status: 'error',
          duration: 3000,
          isClosable: true
        })
      }
    }
  }

  const renderFace = (face: DBT.FaceT) => {
    return <UserPhoto userId={user.id} face={face} />
  }

  const renderPasteImageInput = () => {
    if (user) {
      return <ImagePaste onSelect={onFilesPicked} />
    }
  }

  return (
    <VStack pt={6} w='full' align={'start'}>
      <Label>Photos:</Label>
      <HStack>
        <FilesPickerButton
          options={{
            accept: { 'image/jpeg': [], 'image/png': [], 'image/heic': [] }
          }}
          onFilesPicked={onFilesPicked}
          isLoading={processingFiles}
        />
        {renderPasteImageInput()}
      </HStack>
      <VStack w='full' pt={6} spacing={6}>
        {_.map(sortedFaces, renderFace)}
      </VStack>
    </VStack>
  )
}

export default UserPhotos
