import { extendTheme } from '@chakra-ui/react'
import '@fontsource/geist-sans'

const theme = extendTheme({
  colors: {
    zinc: {
      50: '##fafafa',
      100: '#f4f4f5',
      200: '#e4e4e7',
      300: '#d4d4d8',
      400: '#a1a1aa',
      500: '#71717a',
      600: '#52525b',
      700: '#3f3f46',
      800: '#27272a',
      900: '#18181b',
      950: '#09090b'
    },
    blue: {
      50: '#eff6ff',
      100: '#dbeafe',
      200: '#bfdbfe',
      300: '#93c5fd',
      400: '#60a5fa',
      500: '#3b82f6',
      600: '#2563eb',
      700: '#1d4ed8',
      800: '#1e40af',
      900: '#1e3a8a',
      950: '#172554'
    },
    slate: {
      50: '#f2f3f5',
      100: '#e6e7ea',
      200: '#cdcfd5',
      300: '#b3b6bf',
      400: '#9a9ea9',
      500: '#818693',
      600: '#686e7c',
      700: '#4e5565',
      800: '#353c4c',
      900: '#1b1f32',
      950: '#121524'
    }
  },
  fonts: {
    heading: '\'Geist Sans\', \'sans-serif\'',
    body: '\'Geist Sans\', \'sans-serif\''
  },
  styles: {
    global: {
      body: {
        color: 'zinc.900'
      }
    }
  },
  components: {
    Heading: {
      sizes: {
        md: {
          fontSize: '24px'
        }
      }
    },
    Table: {
      variants: {
        simple: {
          th: {
            textTransform: 'none',
            fontSize: 'sm',
            fontWeight: '600',
            letterSpacing: 'normal',
            color: 'zinc.500'
          },
          td: {
            textTransform: 'none',
            fontSize: 'sm',
            fontWeight: '500',
            letterSpacing: 'normal',
            color: 'zinc.950'
          }
        }
      }
    },
    Alert: {
      variants: {
        customInfo: {
          container: {
            borderWidth: 1,
            borderColor: 'zinc.600',
            background: 'white',
            rounded: 'sm'
          },
          title: {
            color: 'zinc.950',
            fontSize: 'sm',
            fontWeight: '700'
          },
          description: {
            color: 'zinc.950',
            fontSize: 'xs',
            fontWeight: '500'
          }
        }
      }
    },
    Button: {
      variants: {
        fsGhost: {
          bg: 'transparent',
          color: 'zinc.950',
          _hover: {
            bg: 'zinc.100'
          }
        },
        primary: {
          bg: 'zinc.950',
          color: 'white',
          _hover: {
            bg: 'zinc.800'
          },
          _disabled: {
            bg: 'zinc.400',
            color: 'zinc.200',
            _hover: {
              bg: 'zinc.400'
            }
          }
        }
      }
    },
    Switch: {
      baseStyle: {
        track: {
          _checked: {
            bg: 'zinc.950'
          }
        }
      }
    },
    Checkbox: {
      baseStyle: {
        control: {
          _checked: {
            bg: 'zinc.950',
            borderColor: 'zinc.950',
            color: 'white',
            _hover: {
              bg: 'zinc.800',
              borderColor: 'zinc.800'
            }
          }
        }
      }
    },
    Card: {
      baseStyle: {
        borderRadius: 'lg',
        borderWidth: '1px',
        backgroundColor: 'card',
        color: 'cardForeground',
        boxShadow: 'sm'
      }
    },
    CardHeader: {
      baseStyle: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1.5',
        padding: '6'
      }
    },
    CardTitle: {
      baseStyle: {
        fontSize: '2xl',
        fontWeight: 'semibold',
        lineHeight: 'none',
        letterSpacing: 'tight'
      }
    },
    CardDescription: {
      baseStyle: {
        fontSize: 'sm',
        color: 'mutedForeground'
      }
    },
    CardContent: {
      baseStyle: {
        padding: '6',
        paddingTop: '0'
      }
    },
    CardFooter: {
      baseStyle: {
        display: 'flex',
        alignItems: 'center',
        padding: '6',
        paddingTop: '0'
      }
    }
  },
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false
  }
})

export default theme
