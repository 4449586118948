import { useState, useEffect, useMemo, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Column,
  Row,
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  flexRender,
  SortingState
} from '@tanstack/react-table'
import { SearchIcon, ArrowUpDownIcon } from '@chakra-ui/icons'
import {
  Avatar,
  Box,
  HStack,
  Text,
  IconButton,
  Tag,
  Image,
  // Tooltip,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Skeleton
} from '@chakra-ui/react'
import _ from 'lodash'

import { Button } from 'components/ui/Button'
import { Input } from 'components/ui/Input'
import { DateRangePicker } from 'pages/sessions/DateRangePicker'
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/Popover'
import { DraggableColumnList } from 'pages/sessions/DraggableColumnList'
import { ColumnFilterDropdown } from 'pages/sessions/ColumnFilterDropdown'
import NavBar from 'components/NavBar'
import { FaceSignSession } from 'pages/Sessions'
import { DateRange } from 'react-day-picker'


const colors = ['blue', 'gray', 'yellow', 'cyan', 'green', 'purple', 'orange', 'pink', 'teal', 'red']

export function SessionsTable({
  data,
  loading,
  setDateRange,
  pageSize,
  filterValue,
  setFilterValue
}: {
  data: FaceSignSession[]
  loading: boolean
  setDateRange: (v: DateRange) => void
  pageSize: number
  filterValue: string
  setFilterValue: (v: string) => void
}) {
  const navigate = useNavigate()
  const [sorting, setSorting] = useState<SortingState>([])
  const [columnOrder, setColumnOrder] = useState<string[]>([])
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: pageSize
  })
  const platformOptions = useMemo(() => [...new Set(data.map(column => column.platform))], [data, loading])
  const browserOptions = useMemo(() => [...new Set(data.map(column => column.browser))], [data, loading])
  const browserColorMap = useMemo(() => {
    const map: { [key: string]: string } = {}
    browserOptions.forEach((browser, index) => {
      _.set(map, browser, colors[index + 2 % colors.length])
    })
    return map
  }, [platformOptions])

  const platformColorMap = useMemo(() => {
    const map: { [key: string]: string } = {}
    platformOptions.forEach((platform, index) => {
      _.set(map, platform, colors[index % colors.length])
    })
    return map
  }, [platformOptions])

  useEffect(() => {
    setPagination(prev => ({
      pageIndex: prev.pageIndex,
      pageSize: pageSize
    }))
  }, [pageSize, loading])

  const columns = useCallback((data: FaceSignSession[]) => [
    {
      accessorKey: 'sessionId',
      header: ({ column }: { column: Column<FaceSignSession, unknown> }) => (
        <HStack align='center' minW='11rem'>
          <Text fontWeight='medium'>Session ID</Text>
          <Button
            variant='ghost'
            size='sm'
            h='8'
            w='8'
            p='0'
            ml='2'
            color={column.getIsSorted() ? 'blue.600' : 'gray.500'}
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            <ArrowUpDownIcon boxSize='4' />
          </Button>
        </HStack>
      )
    },
    {
      accessorKey: 'dateTime',
      header: 'Date/Time'
    },
    {
      accessorKey: 'email',
      header: ({ column }: { column: Column<FaceSignSession, unknown> }) => (
        <HStack align='center'>
          <Text fontWeight='medium'>Email</Text>
          <Button
            variant='ghost'
            size='sm'
            h='8'
            w='8'
            p='0'
            ml='2'
            color={column.getIsSorted() ? 'blue.600' : 'gray.500'}
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            <ArrowUpDownIcon boxSize='4' />
          </Button>
        </HStack>
      )
    },
    {
      accessorKey: 'userName',
      header: ({ column }: { column: Column<FaceSignSession, unknown> }) => (
        <HStack align='center'>
          <Text fontWeight='medium'>Name</Text>
          <Button
            variant='ghost'
            size='sm'
            h='8'
            w='8'
            p='0'
            ml='2'
            color={column.getIsSorted() ? 'blue.600' : 'gray.500'}
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            <ArrowUpDownIcon boxSize='4' />
          </Button>
        </HStack>
      )
    },
    // {
    //   accessorKey: 'env',
    //   header: ({ column }: { column: Column<FaceSignSession, unknown> }) => {
    //     const options = useMemo(() => [...new Set(data.map(column => column.env))], [data])
    //     return (
    //       <HStack justify='space-between' align='center'>
    //         <Text fontWeight='medium'>Environment</Text>
    //         <ColumnFilterDropdown
    //           column={column}
    //           options={options}
    //         />
    //       </HStack>
    //     )
    //   },
    //   filterFn: (row: Row<FaceSignSession>, id: string, filterValues: string | string[]) => {
    //     if (!filterValues || filterValues.length === 0) return true
    //     return filterValues.includes(row.getValue(id) as string)
    //   }
    // },
    {
      accessorKey: 'avatar',
      header: ({ column }: { column: Column<FaceSignSession, unknown> }) => {
        const options = useMemo(() => [...new Set(data.map(column => column.avatar))], [data])
        return (
          <HStack justify='space-between' align='center'>
            <Text fontWeight='medium'>Avatar</Text>
            <ColumnFilterDropdown
              column={column}
              options={options}
            />
          </HStack>
        )
      },
      cell: ({ row }: { row: Row<FaceSignSession> }) => (
        <Text
          px={2}
          py={1}
          borderRadius='full'
          bg='purple.100'
          color='purple.800'
          fontSize='xs'
          fontWeight='semibold'
          textAlign='center'
        >
          {row.original.avatar}
        </Text>
      ),
      filterFn: (row: { getValue: (id: string) => string }, id: string, filterValues: string | string[]) => {
        if (!filterValues || filterValues.length === 0) return true
        return filterValues.includes(row.getValue(id))
      }

    },
    {
      accessorKey: 'appVersion',
      header: 'App Version'
    },
    // {
    //   accessorKey: 'demoVersion',
    //   header: 'Demo Version',
    //   cell: ({ row }: { row: Row<FaceSignSession> }) => (
    //     <Text
    //       px={2}
    //       py={1}
    //       borderRadius='full'
    //       fontSize='xs'
    //       fontWeight='semibold'
    //       bg={row.original.demoVersion === 'Demo 1' ? 'blue.100' : row.original.demoVersion === 'Demo 2' ? 'green.100' : 'purple.100'}
    //       color={row.original.demoVersion === 'Demo 1' ? 'blue.800' : row.original.demoVersion === 'Demo 2' ? 'green.800' : 'purple.800'}
    //     >
    //       {row.original.demoVersion}
    //     </Text>
    //   )
    // },
    {
      accessorKey: 'interactionId',
      header: ({ column }: { column: Column<FaceSignSession, unknown> }) => (
        <HStack spacing={2} alignItems='center'>
          <Text fontWeight='medium'>Interaction ID</Text>
          <IconButton
            variant='ghost'
            size='sm'
            aria-label='Sort'
            icon={<ArrowUpDownIcon boxSize={4} />}
            color={column.getIsSorted() ? 'blue.600' : 'gray.500'}
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          />
        </HStack>
      )
    },
    {
      accessorKey: 'verificationId',
      header: ({ column }: { column: Column<FaceSignSession, unknown> }) => (
        <HStack spacing={2} alignItems='center'>
          <Text fontWeight='medium'>Verification ID</Text>
          <IconButton
            variant='ghost'
            size='sm'
            aria-label='Sort'
            icon={<ArrowUpDownIcon boxSize={4} />}
            color={column.getIsSorted() ? 'blue.600' : 'gray.500'}
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          />
        </HStack>
      )
    },
    {
      accessorKey: 'platform',
      header: ({ column }: { column: Column<FaceSignSession, unknown> }) => {
        return (
          <HStack spacing={2} justify='space-between' alignItems='center'>
            <Text fontWeight='medium'>Platform</Text>
            <ColumnFilterDropdown
              column={column}
              options={platformOptions}
              colorMap={platformColorMap}
            />
          </HStack>
        )
      },
      cell: ({ row }: { row: Row<FaceSignSession> }) => {
        const platform = row.original.platform as string
        return (
          <Tag
            size='sm'
            bg={platformColorMap[platform] ? `${platformColorMap[platform]}.100` : 'blue.100'}
            color={platformColorMap[platform] ? `${platformColorMap[platform]}.800` : 'blue.800'}
            borderRadius='full'
            px={2}
            py={1}
            fontSize='xs'
            fontWeight='semibold'
          >
            {platform}
          </Tag>
        )
      },
      filterFn: (row: { getValue: (arg0: string) => string }, id: string, filterValues: string | string[]) => {
        if (!filterValues || filterValues.length === 0) return true
        return filterValues.includes(row.getValue(id))
      }
    },
    {
      accessorKey: 'browser',
      header: ({ column }: { column: Column<FaceSignSession, unknown> }) => (
        <HStack spacing={2} justify='space-between' alignItems='center'>
          <Text fontWeight='medium'>Browser</Text>
          <ColumnFilterDropdown
            column={column}
            options={browserOptions}
            colorMap={browserColorMap}
          />
        </HStack>
      ),
      cell: ({ row }: { row: Row<FaceSignSession> }) => {
        const browser = row.original.browser
        return (
          <Tag
            size='sm'
            bg={browserColorMap[browser] ? `${browserColorMap[browser]}.100` : 'yellow.100'}
            color={browserColorMap[browser] ? `${browserColorMap[browser]}.800` : 'yellow.800'}
            borderRadius='full'
            px={2}
            py={1}
            fontSize='xs'
            fontWeight='semibold'
          >
            {browser}
          </Tag>
        )
      },
      filterFn: (row: { getValue: (arg0: string) => string }, id: string, filterValues: string | string[]) => {
        if (!filterValues || filterValues.length === 0) return true
        return filterValues.includes(row.getValue(id))
      }
    },
    {
      accessorKey: 'location',
      header: 'Location'
    },
    {
      accessorKey: 'language',
      header: 'Language'
    },
    {
      accessorKey: 'photoThumbnail',
      header: ({ column }: { column: Column<FaceSignSession, unknown> }) => (
        <HStack spacing={2} alignItems='center'>
          <Text fontWeight='medium'>Photo Thumbnail</Text>
          <Button
            variant='ghost'
            size='sm'
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
            color={column.getIsSorted() ? 'blue.600' : 'gray.500'}
          >
            <ArrowUpDownIcon boxSize={4} />
          </Button>
        </HStack>
      ),
      cell: ({ row }: { row: Row<FaceSignSession> }) => (
        row.original.photoThumbnail ? (
          <Image
            src={row.original.photoThumbnail}
            alt='Thumbnail'
            boxSize='30px'
            objectFit='cover'
            borderRadius='full'
          />
        ) : (
          <Avatar boxSize='30px' position='relative' zIndex={1} />
        )
      )
    },
    {
      accessorKey: 'isNewUser',
      header: 'New User',
      cell: ({ row }: { row: Row<FaceSignSession> }) => (row.original.isNewUser ? 'Yes' : 'No')
    },
    {
      accessorKey: 'isDeepfake',
      header: 'Deepfake',
      cell: ({ row }: { row: Row<FaceSignSession> }) => (row.original.isDeepfake ? 'Yes' : 'No')
    },
    {
      accessorKey: 'isLivePerson',
      header: 'Live Person',
      cell: ({ row }: { row: Row<FaceSignSession> }) => (row.original.isLivePerson ? 'Yes' : 'No')
    },
    {
      accessorKey: 'ageRange',
      header: 'Age Range'
    },
    // {
    //   accessorKey: 'source',
    //   header: 'Source',
    //   cell: ({ row }: { row: Row<FaceSignSession> }) => {
    //     const source = row.original.source
    //     let colorScheme = 'gray'
    //     if (source === 'Organic') colorScheme = 'green'
    //     if (source === 'Marketing') colorScheme = 'blue'
    //     if (source === 'Referral') colorScheme = 'orange'

    //     return (
    //       <Tag size='sm' colorScheme={colorScheme} borderRadius='full'>
    //         {source}
    //       </Tag>
    //     )
    //   }
    // },
    {
      accessorKey: 'sessionCompleted',
      header: 'Session Completed',
      cell: ({ row }: { row: Row<FaceSignSession> }) => {
        const isCompleted = row.original.sessionCompleted
        return (
          <Tag size='sm' colorScheme={isCompleted ? 'green' : 'red'} borderRadius='full'>
            {isCompleted ? 'Yes' : 'No'}
          </Tag>
        )
      }
    }
    // {
    //   accessorKey: 'errorMessage',
    //   header: ({ column }: { column: Column<FaceSignSession, unknown> }) => (
    //     <HStack spacing={2} align='center'>
    //       <Text fontWeight='medium'>Error Message</Text>
    //       <IconButton
    //         variant='ghost'
    //         size='sm'
    //         aria-label='Sort'
    //         icon={<ArrowUpDownIcon boxSize={4} />}
    //         onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
    //       />
    //     </HStack>
    //   ),
    //   cell: ({ row }: { row: Row<FaceSignSession> }) => (
    //     row.original.errorMessage ? (
    //       <Tooltip label={row.original.errorMessage} aria-label='Error message tooltip'>
    //         <Text color='red.500' isTruncated maxW='200px' title={row.original.errorMessage}>
    //           {row.original.errorMessage.substring(0, 50)}...
    //         </Text>
    //       </Tooltip>
    //     ) : null
    //   )
    // }
  ], [data, loading])

  const memoizedColumns = useMemo(() => columns(data), [data])
  const [columnVisibility, setColumnVisibility] = useState<{ [key: string]: boolean }>({})

  useEffect(() => {
    setColumnVisibility(memoizedColumns.reduce((res, v) => {
      res = { ...res, [v.accessorKey]: true }
      return res
    }, {}))
  }, [memoizedColumns])

  const handleColumnVisibilityChange = (columnId: string, isVisible: boolean) => {
    setColumnVisibility(prev => {
      const res = { ...prev }
      _.set(res, columnId, isVisible)
      return res
    })

    table.setColumnVisibility(prev => ({
      ...prev,
      [columnId]: isVisible
    }))
  }

  const handleAllColumnVisibilityChange = (variant: 'clear' | 'select') => {
    setColumnVisibility(memoizedColumns.reduce((res, v) => {
      res = { ...res, [v.accessorKey]: variant === 'select' }
      return res
    }, {}))
  }

  const handleColumnOrderChange = (newOrder: string[]) => {
    setColumnOrder(newOrder)
    table.setColumnOrder(newOrder)
  }

  const table = useReactTable({
    data,
    columns: useMemo(
      () =>
        loading
          ? memoizedColumns.map((column) => ({
            ...column,
            cell: () => <Skeleton height='10px' width='100%' />
          }))
          : memoizedColumns,
      [loading, memoizedColumns]
    ),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
    onPaginationChange: setPagination,
    state: {
      sorting,
      columnVisibility,
      columnOrder,
      pagination
    },
    initialState: {
      pagination: {
        pageSize
      }
    }
  })

  const navbarActions = useMemo(() => (
    <Box display='flex' alignItems='center' gap='4' zIndex={50}>
      <Box position='relative' flex='1' maxW='16rem' minW='14rem'>
        <SearchIcon
          position='absolute'
          left='3'
          top='50%'
          transform='translateY(-50%)'
          color='gray.400'
          zIndex='10'
        />
        <Input
          id='global-search'
          defaultValue={filterValue}
          onChange={(e) => {
            setFilterValue(e.target.value)
          }}
          placeholder='Search all columns...'
          color='gray.400'
          pl='10'
          w='100%'
        />
      </Box>
      <DateRangePicker onDateRangeChange={setDateRange} />
      <Popover>
        <PopoverTrigger>
          <Button variant='outline'>Columns</Button>
        </PopoverTrigger>
        <PopoverContent
          w='300px'
          p='0'
          bgColor='white'
          align='end'
          style={{
            maxHeight: '70vh',
            overflowY: 'auto',
            zIndex: 50,
            position: 'relative'
          }}
        >
          <DraggableColumnList
            columns={memoizedColumns.map(col => ({ id: col.accessorKey, label: typeof col.header === 'string' ? col.header : col.accessorKey }))}
            columnVisibility={columnVisibility}
            onColumnVisibilityChange={handleColumnVisibilityChange}
            onColumnOrderChange={handleColumnOrderChange}
            handleAllColumnVisibilityChange={handleAllColumnVisibilityChange}
          />
        </PopoverContent>
      </Popover>
    </Box>
  ), [columnVisibility, memoizedColumns])

  return (
    <Box display='flex' flexDir='column' h='100%'>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <NavBar title='FaceSign Sessions' actions={navbarActions} />
      </Box>
      <Box flexGrow='1' overflow='hidden'>
        <Box h='100%' display='flex' flexDir='column'>
          <Box
            display='flex'
            flexGrow='1'
            overflow='auto'
            style={{
              height: 'calc(100vh - 12rem)',
              display: 'flex',
              flexDirection: 'column'
            }}
            width={{
              lg: 'calc(100vw - var(--chakra-sizes-60))',
              base: '100vw'
            }}
          >
            <Table
              minW='100%'
              borderBottomWidth='1px'
              borderBottomColor='gray.300'
              size='sm'
              sx={{
                'td, th': {
                  py: '2',
                  lineHeight: 'short'
                }
              }}
            >
              <Thead position='sticky' top='0' bgColor='gray.50' zIndex={10}>
                {table.getHeaderGroups().map((headerGroup) => (
                  <Tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <Th
                        key={header.id}
                        px='4'
                        py='3'
                        textAlign='left'
                        fontSize='small'
                        fontWeight='medium'
                        color='gray.500'
                        textTransform='uppercase'
                        bgColor='gray.50'
                        letterSpacing='0.05em'
                        style={{ display: columnVisibility[header.column.id] ?? false ? 'table-cell' : 'none' }}
                      >
                        {header.isPlaceholder ? null : (
                          flexRender(header.column.columnDef.header, header.getContext())
                        )}
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>
              <Tbody bgColor='white' flex='none'>
                {table.getRowModel().rows.map((row) => (
                  <Tr
                    key={row.id}
                    _hover={{
                      bgColor: 'gray.100'
                    }}
                    height='40px'
                    cursor='pointer'
                    onClick={() => navigate(`/session/${row.original.sessionId}`)}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <Td
                        key={cell.id}
                        px='3'
                        py='1'
                        whiteSpace='nowrap'
                        style={{
                          display: columnVisibility[cell.column.id] ?? false ? 'table-cell' : 'none',
                          verticalAlign: 'middle'
                        }}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </Td>
                    ))}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

