import { Box, Button, HStack, VStack } from '@chakra-ui/react'
import CodeEditor from 'components/CodeEditor'
import { useState, FC, useEffect } from 'react'

type Props = {
  value: string
  onChange: (v: string) => void
  validate?: (v: string) => string | null
}

const CodeEditorStandalone: FC<Props> = ({ value, onChange, validate }) => {
  const [v, setV] = useState(value)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    setV(value)
  }, [value])

  const apply = () => {
    if (validate) {
      const error = validate(v)
      if (error) {
        setError(error)
      } else {
        setError(null)
        onChange(v)
      }
    } else {
      try {
        JSON.parse(v)
        onChange(v)
        setError(null)
      } catch (e) {
        setError('Invalid JSON format')
      }
    }
  }

  return (
    <VStack
      w='full'
      bg='zinc.100'
      p={4}
      border={1}
      borderColor={'zinc.800'}
      align={'flex-start'}
    >
      <Box w='full'>
        <CodeEditor
          value={v}
          onChange={setV}
          onKeyDown={() => null}
          // onBlur={apply}
        />
      </Box>
      <HStack justify={'space-between'} w='full'>
        <Box flex={1}>{error && <Box color='red.500'>{error}</Box>}</Box>
        <HStack>
          <Button
            variant={'primary'}
            colorScheme='zinc'
            // isDisabled={v === value}
            onClick={apply}
          >
            Done
          </Button>
        </HStack>
      </HStack>
    </VStack>
  )
}

export default CodeEditorStandalone
