import { useState, useEffect } from 'react'
import { DragDropContext, Droppable, Draggable, DropResult } from '@hello-pangea/dnd'
import { DragHandleIcon } from '@chakra-ui/icons'
import { Checkbox, Box, Flex, Text } from '@chakra-ui/react'
import _ from 'lodash'

import { Button } from 'components/ui/Button'

interface DraggableColumnListProps {
  columns: { id: string; label: string }[];
  columnVisibility: { [key: string]: boolean };
  onColumnVisibilityChange: (columnId: string, isVisible: boolean) => void;
  handleAllColumnVisibilityChange: (variant: 'clear' | 'select') => void
  onColumnOrderChange: (newOrder: string[]) => void;
}

export function DraggableColumnList({
  columns,
  columnVisibility,
  onColumnVisibilityChange,
  handleAllColumnVisibilityChange,
  onColumnOrderChange
}: DraggableColumnListProps) {
  const [internalColumns, setInternalColumns] = useState(columns)

  useEffect(() => {
    setInternalColumns(columns)
  }, [columns])

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return
    const newColumns = Array.from(internalColumns)
    const [reorderedColumn] = newColumns.splice(result.source.index, 1)
    newColumns.splice(result.destination.index, 0, reorderedColumn)
    setInternalColumns(newColumns)
    onColumnOrderChange(newColumns.map((col) => col.id))
  }

  const allSelected = _.every(columnVisibility, v => v)
  const noneSelected = _.every(columnVisibility, v => !v)

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Box p={4} gap={2}>
        <Flex justifyContent='space-between' mb={4}>
          <Button
            onClick={() => handleAllColumnVisibilityChange('select')}
            isDisabled={allSelected}
            variant='outline'
            size='sm'
          >
            Select All
          </Button>
          <Button
            onClick={() => handleAllColumnVisibilityChange('clear')}
            isDisabled={noneSelected}
            variant='outline'
            size='sm'
          >
            Clear All
          </Button>
        </Flex>
        <Droppable droppableId='columns'>
          {(provided) => (
            <Box
              ref={provided.innerRef}
              {...provided.droppableProps}
              display='flex'
              flexDirection='column'
              gap={2}
            >
              {internalColumns.map((column, index) => (
                <Draggable key={column.id} draggableId={column.id} index={index}>
                  {(provided, snapshot) => (
                    <Flex
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      align='center'
                      p={2}
                      borderRadius='md'
                      cursor='pointer'
                      bg={snapshot.isDragging ? 'gray.100' : 'transparent'}
                      _hover={{ bg: 'gray.50' }}
                      style={{
                        ...provided.draggableProps.style,
                        userSelect: 'none'
                      }}
                      gap={2}
                    >
                      <Box {...provided.dragHandleProps}>
                        <DragHandleIcon boxSize={5} color='gray.500' />
                      </Box>
                      <Checkbox
                        isChecked={columnVisibility[column.id] ?? false}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) =>
                          onColumnVisibilityChange(column.id, e.target.checked)
                        }
                      />
                      <Text
                        fontSize='sm'
                        onClick={() =>
                          onColumnVisibilityChange(column.id, !(columnVisibility[column.id] ?? false))
                        }>
                        {column.label}
                      </Text>
                    </Flex>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </Box>
    </DragDropContext>
  )
}
