import {
  AddIcon,
  CheckCircleIcon,
  CloseIcon,
  CopyIcon,
  DeleteIcon,
  EditIcon
} from '@chakra-ui/icons'
import { Box, Button, Text, Tooltip, VStack } from '@chakra-ui/react'
import CodeEditor from 'components/CodeEditor'
import {
  githubLightTheme,
  IconCopy,
  JsonEditor,
  NodeData
} from 'json-edit-react'
import { FC } from 'react'

type Props = {
  data: Record<string, any>
  onChange: (upd: Record<string, any>) => void
}

const JsonInput: FC<Props> = ({ data, onChange }) => {
  // console.log('data', data)

  const setData = (data: Record<string, any>) => {
    // console.log('setData', data)
    onChange(data)
  }

  const onUpdate = (e: any) => {
    // console.log('onUpdate', e)
  }

  return (
    <VStack w='full' css={{}}>
      <JsonEditor
        theme={[
          githubLightTheme,
          {
            container: {
              width: '100%',
              maxWidth: '100%',
              backgroundColor: 'zinc.100',
              padding: '8px 16px 8px 20px',
              fontSize: '14px' // Adjust the font size here
            }
          }
        ]}
        onUpdate={onUpdate}
        keySort
        maxWidth={'full'}
        data={data}
        setData={data => setData(data as Record<string, any>)}
        enableClipboard={false}
        showCollectionCount={false}
        icons={{
          edit: (
            <Tooltip label='Edit'>
              <EditIcon color='zinc.800' />
            </Tooltip>
          ),
          add: (
            <Tooltip label='Add key'>
              <AddIcon color='zinc.800' />
            </Tooltip>
          ),
          delete: (
            <Tooltip label='Delete'>
              <DeleteIcon color='red.500' />
            </Tooltip>
          ),
          ok: (
            <Tooltip label='Done'>
              <CheckCircleIcon color='zinc.800' w={4} h={4} mx={1} />
            </Tooltip>
          ),
          cancel: (
            <Tooltip label='cancel'>
              <CloseIcon color='zinc.800' w={3} h={3} mx={1} />
            </Tooltip>
          )
        }}
        TextEditor={props => (
          <Box w='full'>
            <CodeEditor {...props} />
          </Box>
        )}
      />
    </VStack>
  )
}

export default JsonInput
