import * as React from 'react'
import { CalendarIcon, CloseIcon } from '@chakra-ui/icons'
import { addDays, format } from 'date-fns'
import { DateRange } from 'react-day-picker'
import { Box, Grid, IconButton } from '@chakra-ui/react'

import { Button } from 'components/ui/Button'
import { Calendar } from 'components/ui/Calendar'
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/Popover'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/Select'

export function DateRangePicker({ onDateRangeChange }: { onDateRangeChange: (v: DateRange) => void }) {
  const [date, setDate] = React.useState<DateRange | undefined>()
  const [preset, setPreset] = React.useState<string>('all')

  const handleDateRangeChange = (newDate: DateRange | undefined) => {
    setDate(newDate)
    if (newDate?.from && newDate?.to) {
      onDateRangeChange({ from: newDate.from, to: newDate.to })
    }
  }

  const handlePresetChange = (newPreset: string) => {
    setPreset(newPreset)
    const today = new Date()
    let from: Date | undefined, to: Date | undefined

    switch (newPreset) {
      case 'all': {
        from = undefined
        to = undefined
        break
      }
      case 'today': {
        from = today
        to = today
        break
      }
      case 'yesterday': {
        from = addDays(today, -1)
        to = addDays(today, -1)
        break
      }
      case 'last7days': {
        from = addDays(today, -6)
        to = today
        break
      }
      case 'last30days': {
        from = addDays(today, -29)
        to = today
        break
      }
      default: return
    }

    setDate(from && to ? { from, to } : undefined)
    onDateRangeChange({ from, to })
  }

  return (
    <Grid gap='2'>
      <Popover>
        <PopoverTrigger>
          <Box position='relative'>
            <Button
              id='date'
              variant='outline'
              w='300px'
              justifyContent='start'
              textAlign='left'
              fontWeight='normal'
              color={!date ? 'gray.500' : 'gray.900'}
            >
              <CalendarIcon mr='2' h='2' w='4' />
              {preset === 'all' && !date?.from && !date?.to ? (
                <span>All dates</span>
              ) : date?.from ? (
                date.to ? (
                  <>
                    {format(date.from, 'LLL dd, y')} - {format(date.to, 'LLL dd, y')}
                  </>
                ) : (
                  format(date.from, 'LLL dd, y')
                )
              ) : (
                <span>Pick a date</span>
              )}
            </Button>

            {(preset !== 'all' || date?.from) && (
              <IconButton
                aria-label='Clear date'
                icon={<CloseIcon />}
                size='xs'
                variant='ghost'
                position='absolute'
                right='2'
                top='2'
                zIndex='10'
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  handlePresetChange('all')
                }}
              />
            )}
          </Box>
        </PopoverTrigger>
        <PopoverContent w='auto' p='0' align='start'>
          <Select value={preset} onValueChange={handlePresetChange}>
            <SelectTrigger>
              <SelectValue placeholder='Select...' />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value='all'>All</SelectItem>
              <SelectItem value='today'>Today</SelectItem>
              <SelectItem value='yesterday'>Yesterday</SelectItem>
              <SelectItem value='last7days'>Last 7 days</SelectItem>
              <SelectItem value='last30days'>Last 30 days</SelectItem>
            </SelectContent>
          </Select>
          <Calendar
            mode='range'
            defaultMonth={date?.from}
            selected={date}
            onSelect={handleDateRangeChange}
            numberOfMonths={2}
          />
        </PopoverContent>
      </Popover>
    </Grid>
  )
}
