import { FC } from 'react'
import { Button, Flex, Text, VStack } from '@chakra-ui/react'
import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import Label from 'components/Label'

type Props = {
  value?: string
  onSelect: (quality: string) => void
}

const AvatarQualitySelect: FC<Props> = ({ value = 'high', onSelect }) => {
  const options = ['high', 'medium', 'low']

  const renderOption = (q: string) => (
    <MenuItem key={q} value={q} onClick={() => onSelect(q)}>
      <Flex justifyContent='space-between' alignItems='center'>
        <Flex alignItems='baseline' gap={4}>
          <Text>{q}</Text>
        </Flex>
      </Flex>
    </MenuItem>
  )

  return (
    <VStack w='full' align={'flex-start'}>
      <Label>Avatar quality</Label>
      <Flex alignItems='center' gap={2} w='full'>
        <Menu placement='bottom-start'>
          <MenuButton
            as={Button}
            size='sm'
            variant={'outline'}
            rightIcon={<ChevronDownIcon />}
            w='full'
          >
            {renderOption(value)}
            {/* {value ? renderOption(value) : 'Select option'} */}
          </MenuButton>
          <MenuList maxH={'md'} overflowY='auto'>
            {options.map(renderOption)}
          </MenuList>
        </Menu>
      </Flex>
    </VStack>
  )
}

export default AvatarQualitySelect
