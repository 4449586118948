import { liteClient } from 'algoliasearch/lite'
import { createAutocomplete } from '@algolia/autocomplete-core'
import { getAlgoliaResults } from '@algolia/autocomplete-preset-algolia'
import { useMemo, useState, useRef, useEffect, FC } from 'react'
import get from 'lodash/get'
import config from 'src/config'
import {
  Box,
  // FormControl,
  HStack,
  Image,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  StackDivider,
  Text,
  VStack,
  useDisclosure
} from '@chakra-ui/react'
import _ from 'lodash'

const searchClient = liteClient(config.algoliaAppId, config.algoliaSearchKey)

const algoliaIndex = config.algoliaUsersIndex

type Props = {
  onSelect: (id: string) => void
}

const UsersSearch: FC<Props> = ({ onSelect }) => {
  const { onOpen, onClose, isOpen } = useDisclosure()
  const inputRef = useRef<HTMLInputElement>(null)
  const [suggestions, setAutocompleteState] = useState([])
  const [value, setValue] = useState('')

  useEffect(() => {
    suggestions.length > 0 ? onOpen() : onClose()
  }, [suggestions])

  // console.log('suggestions', suggestions)

  const autocomplete = useMemo(
    () =>
      createAutocomplete({
        onStateChange({ state }) {
          // (2) Synchronize the Autocomplete state with the React state.
          // @ts-expect-error todo: error description
          setAutocompleteState(get(state, ['collections', 0, 'items'], []))
        },
        // @ts-expect-error todo: error description
        getSources() {
          return [
            // (3) Use an Algolia index source.
            {
              sourceId: algoliaIndex,
              getItemInputValue({ item }) {
                return item.query
              },
              getItems({ query }) {
                return getAlgoliaResults({
                  searchClient,
                  queries: [
                    {
                      indexName: algoliaIndex,
                      //@ts-expect-error todo: error description
                      query,
                      params: {
                        hitsPerPage: 8
                        // highlightPreTag: '<mark>',
                        // highlightPostTag: '</mark>'
                      }
                    }
                  ]
                })
              },
              getItemUrl({ item }) {
                return item.url
              }
            }
          ]
        }
      }),
    []
  )

  const onSuggestionClick = (id: string) => {
    if (inputRef.current) {
      setAutocompleteState([])
      onSelect(id)
      autocomplete.setQuery('')
      onClose()
      setValue('')
      inputRef.current.blur() // Remove focus from input field
    }
  }

  const renderSuggestion = ({
    objectID,
    ...rest
  }: {
    objectID: string
    name: string
    email?: string
    jobTitle?: string
    company?: string
    city?: string
    country?: string
    photoUrl?: string
  }) => {
    const data = _.omit(rest, [
      'objectID',
      'id',
      'photoUrl',
      'name',
      'email',
      'city',
      'faces',
      'jobTitle',
      'company',
      'country',
      'path',
      'lastmodified',
      '_highlightResult',
      '__autocomplete_indexName',
      '__autocomplete_algoliaCredentials',
      '__autocomplete_id'
    ])
    return (
      <HStack
        align={'flex-start'}
        w='full'
        key={objectID}
        _hover={{ bg: 'zinc.100' }}
        cursor={'pointer'}
        onClick={() => onSuggestionClick(objectID)}
      >
        <Box w='60px' h='60px' boxSize='60px' flexShrink={0}>
          <Image
            boxSize='60px'
            objectFit='cover'
            src={_.get(rest, 'photoUrl')}
            alt={_.get(rest, 'name')}
          />
        </Box>
        <VStack flex={1} align='flex-start' w='full' spacing={0} flexShrink={0}>
          <Text color={'zinc.600'} fontSize={'sm'} fontWeight={'semibold'}>
            {_.get(rest, 'name')}
          </Text>
          <Text color={'zinc.900'} fontSize={'x-small'} fontWeight={'semibold'}>
            {_.compact([
              _.get(rest, 'email'),
              _.get(rest, 'jobTitle'),
              _.get(rest, 'company'),
              _.get(rest, 'city'),
              _.get(rest, 'country')
            ]).join(', ')}
          </Text>
          <Text color={'zinc.600'} fontSize={'xx-small'} fontWeight={'normal'}>
            {JSON.stringify(data)}
          </Text>
          {/* <Text color={'zinc.600'} fontSize={'xx-small'} fontWeight={'normal'}>
            {jobTitle} {company}
          </Text>
          <Text color={'zinc.600'} fontSize={'xx-small'} fontWeight={'normal'}>
            {city} {country}
          </Text> */}
        </VStack>
      </HStack>
    )
  }

  return (
    <Popover
      returnFocusOnClose={false}
      isOpen={isOpen}
      onClose={onClose}
      placement='bottom-start'
      closeOnBlur={true}
      autoFocus={false}
      // initialFocusRef={inputRef}
      matchWidth
    >
      <PopoverTrigger>
        <Input
          ref={inputRef}
          variant='flushed'
          size='sm'
          autoFocus={false}
          placeholder='Search by name, ID'
          type={'search'}
          value={value}
          onChange={e => {
            console.log('onchange', e.target.value)
            setValue(e.target.value)
            if (_.size(e.target.value) >= 3) {
              autocomplete
                .getInputProps({ inputElement: inputRef.current })
                //@ts-expect-error todo: error description
                .onChange(e)
            }
          }}
        />
      </PopoverTrigger>
      <PopoverContent w={'full'}>
        <PopoverBody w={'full'}>
          <VStack
            w={'full'}
            divider={<StackDivider color='gray.50' />}
            overflowY={'auto'}
            spacing={1}
            maxH='lg'
          >
            {suggestions.map(renderSuggestion)}
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default UsersSearch
